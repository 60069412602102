import React, { useState, useEffect, useMemo, useContext } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import LandingPageLayout from "../../layouts/landing-page-layout/LandingPageLayout";
import { Link, navigate } from 'gatsby';
import Authenticator from "../../services/Authenticator";
import { NotificationContext } from '../../components/notification/Notification';
import { handleNavigate } from "../../services/Navigate";

import '../login/Login.scss';

const VerifyEmail = () => {
    const { notify } = useContext(NotificationContext);
    const [timeToRedirectInSeconds, setTimeToRedirectInSeconds] = useState(5);
    const [isUserConfirmed, setIsUserConfirmed] = useState(false);
    const [isAlreadyConfirmed, setIsAlreadyConfirmed] = useState(false);
    const [resendError, setResendError] = useState('');
    const [linkExpired, setLinkExpired] = useState(false);
    const urlParams = typeof window !== 'undefined' && window?.location?.search ? new URLSearchParams(window?.location?.search) || undefined : undefined;
    const email = typeof urlParams !== 'undefined' ? urlParams?.get('email') : undefined;
    const code = typeof urlParams !== 'undefined' ? urlParams.get('code') : undefined;
    
    const confirmSignup = async (email, code) => {
        try {
            await Authenticator.confirmSignUp(email, code);
            setIsUserConfirmed(true);
        } catch(error) {
            if (error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
                setIsAlreadyConfirmed(true);
            } else {
                setLinkExpired(true);
            }
        }
    };

    useEffect(() => {
        notify({
            message: resendError,
            type: resendError !== 'We have sent you an email with the activation link.' ? 'critical' : 'success'
        })
    }, [resendError]);

    const resendConfirmationCode = async (email) => {
        try {
            await Authenticator.resendSignUp(email);
            setResendError('We have sent you an email with the activation link.');
        } catch(error) {
            setResendError(error.message);
        }
    }

    useEffect(() => {
        if (isAlreadyConfirmed || isUserConfirmed || linkExpired) {
            return;
        }

        (async() => {
            try {
                await confirmSignup(email, code);
            } catch (error) {
                console.error('Error: ', error);
            }
        })();
    }, [email, code, isAlreadyConfirmed, isUserConfirmed, linkExpired]);

    useEffect(() => {
        if (isAlreadyConfirmed || linkExpired) {
            return;
        }

        const intervalSeconds = setInterval(() => {
            if (timeToRedirectInSeconds > 0) {
                setTimeToRedirectInSeconds(timeToRedirectInSeconds - 1);
            }

            if (timeToRedirectInSeconds === 0) {
                if (isUserConfirmed) {
                    handleNavigate('/account');
                }

                if (isAlreadyConfirmed) {
                    handleNavigate('/login');
                } 

                clearInterval(intervalSeconds);
            }
        }, 1000);

        return () => clearInterval(intervalSeconds);
    });

    const verifyEmailDOM = useMemo(() => {
        if (isUserConfirmed) {
            return <>
                <h1 className='mb-5'>Email Address Verified</h1>
                <p>Congrats! Your email address is now verified. You can start inviting people to complete your Whitelist requirements.</p>
                <p>Redirecting you to the login page in {timeToRedirectInSeconds} seconds...</p>
                <Button onClick={() => handleNavigate('/login')} className='mt-4'>Login</Button>
            </>;
        }

        if (isAlreadyConfirmed) {
            return <>
                <h1 className='mb-5'>Email Address Already Verified</h1>
                <p>It looks like this email address was already verified. Please try to <Link to='/login'>Login</Link> instead. If the account was not verified, please click on the button below to send a new activation link.</p>
            </>;
        }
        
        if (linkExpired) {
            return <>
                <h1 className='mb-5'>This link expired</h1>
                <p>Please try to login instead. If the account was not verified, please click on the button below to send a new activation link.</p>
                {email && <Button disabled={resendError} onClick={() => {(async() => resendConfirmationCode(email))()}} className='mt-4'>Resend Activation Link</Button>}
            </>;
        }
    }, [isUserConfirmed, isAlreadyConfirmed, timeToRedirectInSeconds, linkExpired, resendError]);

    return <LandingPageLayout classNames='landingPage login' onlyLogo={true} pageTitle='Verify Email'>
        <Container>
            <Row>
                <Col xs={12} md={8} xl={6} className='mx-auto'>
                    {verifyEmailDOM}
                </Col>
            </Row>
        </Container>
    </LandingPageLayout>
};

export default VerifyEmail;